import { gql, useQuery } from '@apollo/client'
import { MembershipAllowedToSee } from 'src/types/membership'

export const ALLOWED_TO_SEE_MEMBERSHIP_QUERY = gql`
  query IsAllowedToSeeMembership {
    isAllowedToSeeMembership {
      isAllowed
    }
  }
`

export function useAllowedToSeeMembership() {
  const { data, ...rest } = useQuery<MembershipAllowedToSee>(
    ALLOWED_TO_SEE_MEMBERSHIP_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first'
    }
  )
  return {
    isAllowedToSeeMembership: data?.isAllowedToSeeMembership,
    ...rest
  }
}
