export const FILTER_IDS = {
  ALL: '0-all',
  YOUVE_TRIED: '1-youvetried',
  FAVORITES: '2-fav',
  PREMIUM: '3-premium',
  CUISINE: '4-cuisine',
  CHEF: '5-chef',
  DIET: '6-diet',
  PROTEIN: '7-protein',
  SIDES: '8-sides',
  BREAKFAST: '9-breakfast',
  MEAL_TYPE_PIZZA: '10-pizza',
  MEAL_TYPE_SALADS_AND_BOWLS: '11-salads-and-bowls',
  MEAL_TYPE_SANDWICH_AND_WRAPS: '12-sandwich-and-wraps',
  MEAL_TYPE_TACOS_AND_BURRITOS: '13-tacos-and-burritos',
  MEAL_TYPE_PASTA_AND_NOODLES: '14-pasta-and-noodles',
  MEAL_TYPE_PROTEIN_STARCH: '15-protein-starch',
  MEAL_TYPE_VEG_MEALS: '16-veg-meals',
  MEAL_TYPE_BALANCED_PLATE: '17-balanced-plate',
  MEAL_TYPE_BURGERS: '18-burgers',
  MEAL_TYPE_SOUPS_AND_STEWS: '19-soups-and-stews',
  MEAL_TYPE_BREAKFAST: '20-breakfast',
  SIDES_ALL: '21-sides-all',
  SIDES_VEGGIES_SALADS: '22-veggies-and-salads',
  SIDES_GRAINS_STARCH: '23-grains-and-starch',
  SIDES_PASTA_BREAD: '24-pasta-and-bread',
  SIDES_FINGER_FOOD: '25-finger-food',
  SIDES_SOUPS: '26-side-soups',
  SIDES_PROTEIN: '27-side-protein',
  BREAKFAST_ALL: '28-breakfast-all',
  BREAKFAST_EGGS: '29-eggs',
  BREAKFAST_PANCAKES_WAFFLES: '30-pancakes-waffles',
  BREAKFAST_YOGURT_GRANOLA: '31-yogurt-granola-oatmeal',
  BREAKFAST_BREAD_PASTRIES: '32-bread-pastries',
  BREAKFAST_SMOOTHIES_JUICES: '33-smoothies-juices',
  BREAKFAST_BARS: '34-bars',
  FOR_YOU: '35-for-you',
  MEAL_TYPE_HEALTHY: '36-healthy',
  CHEF_SKU_PROMOTION: '37-chef-sku-promotion',
  SIDES_EXPERIMENT: '38-sides-experiment',
  SIDES_PROTEIN_ONLY: '39-sides-protein-only',
  CHEFS_PANTRY_SAUCES: '40-sauces',
  CHEFS_PANTRY_DIPS: '41-dips',
  CHEFS_PANTRY_CONDIMENTS_DRESSING: '42-condiments-dressing',
  CHEFS_PANTRY_SPICES_SEASONING: '43-spices-seasoning',
  MEAL_TYPE_GREEN_EATING: '64-green-eating',
  FULL_MENU_ALL: '44-full-menu-all',
  FULL_MENU_ADD_AND_SAVE: '45-full-menu-add-and-save',
  FULL_MENU_MAIN_WITH_SIDES: '46-full-menu-main-with-sides',
  FULL_MENU_SALADS_AND_BOWLS: '47-full-menu-salads-and-bowls',
  FULL_MENU_PASTA_AND_NOODLES: '48-full-pasta-and-noodles',
  FULL_MENU_TACOS_AND_BURRITOS: '49-full-menu-tacos-and-burritos',
  FULL_MENU_VEGGIE: '50-full-veggie',
  FULL_MENU_SANDWICHES: '51-full-menu-sandwiches',
  FULL_MENU_PIZZA: '52-full-menu-pizza',
  FULL_MENU_SOUPS_AND_STEWS: '53-full-menu-soups-and-stews',
  FULL_MENU_BREAKFAST: '54-full-breakfast',
  FULL_MENU_SIDES: '55-full-menu-sides',
  FULL_MENU_PROTEIN_ONLY: '56-full-menu-protein-only',
  FULL_MENU_TREATS: '57-full-menu-treats',
  FULL_MENU_SNACKS: '58-full-menu-snacks',
  FULL_MENU_DRINKS: '59-full-menu-drinks',
  FULL_MENU_BUNDLES: '60-full-menu-bundles',
  FULL_MENU_SAUCES_AND_CONDIMENTS: '61-full-menu-sauces-and-condiments',
  FULL_MENU_WELLNESS: '62-full-menu-wellness',
  FULL_MENU_HIDE_PREMIUM: '63-full-menu-hide-premium',
  FULL_MENU_GREAT_FOR_LUNCH: '64-full-menu-wellness-great-for-lunch',
  FULL_MENU_FAMILY_FAVORITES: '65-full-menu-family-favorites', // RENAMED TO KIDS MEALS
  FULL_MENU_ENERGY_BOOST: '66-full-menu-energy-boost',
  FULL_MENU_FINE_DINING: '67-full-menu-fine-dining',
  FULL_MENU_SEASONAL: '68-full-menu-seasonal',
  FULL_MENU_GREEN_EATING: '69-full-menu-green-eating',
  FULL_MENU_TRENDING_NOW: '70-full-menu-trending-now',
  FULL_MENU_TOP_RATED: '71-full-menu-top-rated',
  FULL_MENU_WHATS_NEW: '72full-menu-whats-new',
  FULL_MENU_FOR_YOU: '45-full-menu-for-you',
  FULL_MENU_YOUVE_TRIED: '73-full-menu-youvetried',
  FULL_MENU_FAVORITES: '74-full-menu-favorites',
  FULL_MENU_COLLECTIONS: '75-full-menu-collections',
  MEAL_TYPE_FAMILY_FAVORITES: '76-family-favorites', // RENAMED TO KIDS MEALS
  FULL_MENU_BEST_PERFORMERS: '78-full-menu-best-performers',
  BEST_PERFORMERS: '79-best-performers',
  MEAL_TYPE_CLEAN_EATING: '80-clean-eating',
  FULL_MENU_CLEAN_EATING: '81-full-menu-clean-eating',
  MEAL_TYPE_GLP_1: '82-glp-1',
  FULL_MENU_GLP_1: '83-full-menu-glp-1'
}

export const MEAL_TYPES_FILTERS = [
  FILTER_IDS.MEAL_TYPE_PIZZA,
  FILTER_IDS.MEAL_TYPE_SALADS_AND_BOWLS,
  FILTER_IDS.MEAL_TYPE_SANDWICH_AND_WRAPS,
  FILTER_IDS.MEAL_TYPE_TACOS_AND_BURRITOS,
  FILTER_IDS.MEAL_TYPE_PASTA_AND_NOODLES,
  FILTER_IDS.MEAL_TYPE_FAMILY_FAVORITES,
  FILTER_IDS.MEAL_TYPE_PROTEIN_STARCH,
  FILTER_IDS.MEAL_TYPE_VEG_MEALS,
  FILTER_IDS.MEAL_TYPE_BALANCED_PLATE,
  FILTER_IDS.MEAL_TYPE_BURGERS,
  FILTER_IDS.MEAL_TYPE_SOUPS_AND_STEWS,
  FILTER_IDS.MEAL_TYPE_BREAKFAST,
  FILTER_IDS.MEAL_TYPE_HEALTHY,
  FILTER_IDS.CHEF_SKU_PROMOTION,
  FILTER_IDS.SIDES_EXPERIMENT,
  FILTER_IDS.SIDES_PROTEIN_ONLY,
  FILTER_IDS.MEAL_TYPE_GREEN_EATING,
  FILTER_IDS.MEAL_TYPE_CLEAN_EATING,
  FILTER_IDS.MEAL_TYPE_GLP_1
]
export const FULL_MENU_FILTERS = [
  FILTER_IDS.FULL_MENU_ALL,
  FILTER_IDS.FULL_MENU_ADD_AND_SAVE,
  FILTER_IDS.FULL_MENU_MAIN_WITH_SIDES,
  FILTER_IDS.FULL_MENU_SALADS_AND_BOWLS,
  FILTER_IDS.FULL_MENU_PASTA_AND_NOODLES,
  FILTER_IDS.FULL_MENU_TACOS_AND_BURRITOS,
  FILTER_IDS.FULL_MENU_VEGGIE,
  FILTER_IDS.FULL_MENU_SANDWICHES,
  FILTER_IDS.FULL_MENU_PIZZA,
  FILTER_IDS.FULL_MENU_SOUPS_AND_STEWS,
  FILTER_IDS.FULL_MENU_BREAKFAST,
  FILTER_IDS.FULL_MENU_SIDES,
  FILTER_IDS.FULL_MENU_PROTEIN_ONLY,
  FILTER_IDS.FULL_MENU_TREATS,
  FILTER_IDS.FULL_MENU_SNACKS,
  FILTER_IDS.FULL_MENU_DRINKS,
  FILTER_IDS.FULL_MENU_BUNDLES,
  FILTER_IDS.FULL_MENU_SAUCES_AND_CONDIMENTS,
  FILTER_IDS.FULL_MENU_WELLNESS,
  FILTER_IDS.CHEFS_PANTRY_SPICES_SEASONING,
  FILTER_IDS.FULL_MENU_HIDE_PREMIUM,
  FILTER_IDS.FULL_MENU_GREAT_FOR_LUNCH,
  FILTER_IDS.FULL_MENU_FAMILY_FAVORITES,
  FILTER_IDS.FULL_MENU_ENERGY_BOOST,
  FILTER_IDS.FULL_MENU_FINE_DINING,
  FILTER_IDS.FULL_MENU_SEASONAL,
  FILTER_IDS.FULL_MENU_GREEN_EATING,
  FILTER_IDS.FULL_MENU_TRENDING_NOW,
  FILTER_IDS.FULL_MENU_TOP_RATED,
  FILTER_IDS.FULL_MENU_WHATS_NEW,
  FILTER_IDS.FULL_MENU_FOR_YOU,
  FILTER_IDS.FULL_MENU_YOUVE_TRIED,
  FILTER_IDS.FULL_MENU_FAVORITES,
  FILTER_IDS.FULL_MENU_COLLECTIONS,
  FILTER_IDS.FULL_MENU_BEST_PERFORMERS,
  FILTER_IDS.FULL_MENU_CLEAN_EATING,
  FILTER_IDS.FULL_MENU_GLP_1
]

export const CATEGORIES_FILTERS = [FILTER_IDS.SIDES, FILTER_IDS.BREAKFAST]

export const SUBCATEGORIES_FILTERS = [
  FILTER_IDS.SIDES_VEGGIES_SALADS,
  FILTER_IDS.SIDES_GRAINS_STARCH,
  FILTER_IDS.SIDES_PASTA_BREAD,
  FILTER_IDS.SIDES_FINGER_FOOD,
  FILTER_IDS.SIDES_SOUPS,
  FILTER_IDS.SIDES_PROTEIN,
  FILTER_IDS.BREAKFAST_EGGS,
  FILTER_IDS.BREAKFAST_PANCAKES_WAFFLES,
  FILTER_IDS.BREAKFAST_YOGURT_GRANOLA,
  FILTER_IDS.BREAKFAST_BREAD_PASTRIES,
  FILTER_IDS.BREAKFAST_SMOOTHIES_JUICES,
  FILTER_IDS.BREAKFAST_BARS,
  FILTER_IDS.CHEFS_PANTRY_SAUCES,
  FILTER_IDS.CHEFS_PANTRY_DIPS,
  FILTER_IDS.CHEFS_PANTRY_CONDIMENTS_DRESSING,
  FILTER_IDS.CHEFS_PANTRY_SPICES_SEASONING
]

export const FILTER_INDEX = {
  ALL: 0,
  CUISINE: 1,
  CHEF: 2,
  DIET: 3,
  PROTEIN: 4
}

export const FILTER_INDEX_EXPERIMENT = {
  ALL: 0,
  YOUVE_TRIED: 1,
  FAVORITES: 2,
  PREMIUM: 3,
  DIET: 4,
  PROTEIN: 5,
  CUISINE: 6,
  CHEF: 7
}

export const TAB_ALL_IDS = {
  ALL: FILTER_IDS.ALL,
  YOU_HAVE_TRIED: FILTER_IDS.YOUVE_TRIED,
  FAVORITES: FILTER_IDS.FAVORITES,
  PREMIUM: FILTER_IDS.PREMIUM
}

export const MENU_EMPTY_STATES = {
  FAVORITES: {
    title: 'Nothing here yet...',
    subtitle:
      "Use the heart icon to save your favorite meals. Once you do, you'll find them here.",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: true,
    isTopRated: false
  },
  YOU_HAVE_TRIED: {
    title: 'Nothing here yet...',
    subtitle:
      "It's a great opportunity to explore and try new things from our menu!",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: false
  },
  YOU_HAVE_TRIED_NO_ORDER: {
    title: 'Nothing here yet...',
    subtitle:
      "After you receive your first order, we will show you here the meals you've tried.",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: false
  },
  TOP_RATED: {
    title: 'Nothing here yet...',
    subtitle:
      'We will show your top rated meals here. You can start rating them now.',
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'Rate meals',
    isFavorite: false,
    isTopRated: true
  },
  TOP_RATED_NO_ORDER: {
    title: 'Nothing here yet...',
    subtitle:
      "We will show your top rated meals here. You don't have anything to rate yet. ",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: true
  }
}

export const SORTING_NAMES = {
  MY_PREFERENCES: 'My preferences'
}
