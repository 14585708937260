import { useEffect, useState } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'
import { getDateLabel } from 'src/utils/date'
import {
  MembershipData,
  MembershipOverlayName,
  Step
} from 'src/types/membership'
import { ReactComponent as Alert } from '../../../../assets/svg/alert-circle.svg'
import './MembershipPaused.scss'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { useUnPauseMembership } from 'src/api/Membership/useUnPauseMembership'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { MembershipOverlayActions } from 'src/types/membership'
interface Props {
  onClose?: () => void
  refetch: () => void
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipPaused({
  onClose,
  refetch,
  onContinue,
  membershipData
}: Props) {
  const { unPauseMembership, loading: startLoading } = useUnPauseMembership({
    onCompleted,
    onError
  })

  const { trackModalViewed, trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.paused
  })

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onUnPauseMemership() {
    if (membershipData?.activation?.membershipId) {
      setErrorMessage('')
      unPauseMembership({
        membershipId: membershipData?.activation.membershipId
      })
      trackOptionClicked({
        overlayAction: MembershipOverlayActions.rejoinNow
      })
    } else {
      setErrorMessage('Sorry, we encountered an error. Please try again.')
    }
  }

  function onCompleted(response) {
    if (response.unSkipMembership.success) {
      refetch()
      onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
    } else {
      onError(response)
    }
  }

  function onError(response) {
    setErrorMessage(
      response?.unSkipMembership?.error ||
        'Sorry, we encountered an error. Please try again.'
    )
  }

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="cancel-container">
        {startLoading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <div className="title-container">
          <span className="title">Your Unity Pass is now paused</span>
        </div>
        <div className="description-container">
          <span>
            Your Unity Pass has been paused for one month and will automatically
            reactivate on{' '}
            <span className="bold">
              {membershipData?.activation?.skip?.to
                ? getDateLabel(new Date(membershipData.activation.skip.to))
                : ''}
            </span>
            , when your normal monthly fee will be charged.
            <br />
            <br />
            All your exclusive perks will be ready and waiting for you when you
            return!
          </span>
        </div>
        {errorMessage && (
          <div className="error">
            <div className="image">
              <Alert className="alert-error" />
            </div>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="button-container">
          {onClose && (
            <CuiButton className="secondary-button" onClick={() => onClose()}>
              Go back to homepage
            </CuiButton>
          )}
          <CuiButton className="primary-button" onClick={onUnPauseMemership}>
            Unpause Unity Pass
          </CuiButton>
        </div>
      </div>
    </>
  )
}
