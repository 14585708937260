import { useEffect } from 'react'
import { CuiButton } from 'front-lib'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import { MembershipData, MembershipOverlayName } from 'src/types/membership'
import UnityPassImage from 'src/assets/img/unitypass.png'

import './UnityPassPaused.scss'
import { getDateLabel } from 'src/utils/date'

interface Props {
  onClose?: () => void
  membershipData: MembershipData
}

export function UnityPassPaused({ membershipData, onClose }: Props) {
  const { trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.benefits
  })

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="unity-pass-banner-by-chef">
        <img src={UnityPassImage} alt="Unity Pass" />
      </div>
      <div className="cancel-container">
        <div className="title-container">
          <span className="title">Your Unity Pass is now paused</span>
        </div>
        <div className="description-container">
          <span>
            Your Unity Pass has been paused for one month and will automatically
            reactivate on{' '}
            <span className="bold">
              {membershipData?.activation?.skip?.to
                ? getDateLabel(new Date(membershipData.activation.skip.to))
                : ''}
            </span>
            , when your normal monthly fee will be charged.
            <br />
            <br />
            All your exclusive perks will be ready and waiting for you when you
            return!
          </span>
        </div>
        <div className="button-container">
          {onClose && (
            <CuiButton className="secondary-button" onClick={() => onClose()}>
              Got it
            </CuiButton>
          )}
        </div>
      </div>
    </>
  )
}
