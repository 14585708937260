export function getShippingDays(
  transitDays: number,
  ...deliveryDays: number[]
): number[] {
  if (!deliveryDays.length || isNaN(transitDays)) {
    return []
  }

  const shippingDays = deliveryDays
    .map(day => (day - transitDays + 7) % 7)
    .filter(day => day >= 0 && day <= 6)

  return [...new Set(shippingDays)]
}
