import moment from 'moment'

import { Membership, MembershipData } from 'src/types/membership'

export const getUnityPassHeader = (membershipData?: MembershipData) => {
  if (!membershipData) {
    return {
      title: 'Save with Unity Pass',
      secondaryTitle: '',
      subtitle:
        'Become a Unity Pass holder and start enjoying amazing benefits everytime you order. Here’s what you get:'
    }
  }

  const { activation } = membershipData

  if (activation?.startDate) {
    return {
      title: `THIS MONTH YOU SAVED: $${activation.accumulatedSavings || 0}`,
      secondaryTitle: '',
      subtitle: !activation.stopDate
        ? `Current benefits period: ${formatMembershipDate(
            activation.startDate
          )} to ${formatMembershipDate(activation.endDate)}`
        : `Your Unity Pass has been canceled — current benefits will expire
    after ${formatMembershipDate(activation.endDate)}`
    }
  }

  return {
    title: `Save at least $10 on`,
    secondaryTitle: 'every order!',
    subtitle:
      'Become a Unity Pass holder and start enjoying amazing benefits everytime you order. Here’s what you get:'
  }
}

export const getDiscountInfo = (membership?: Membership) => {
  const discountRate = (membership?.discount?.rate ?? 0) * 100
  const fullCost = membership?.cost?.toFixed(2) ?? '23.99'

  const costWithDiscount =
    Math.floor((membership?.discount?.totalWithDiscount ?? +fullCost) * 100) /
    100
  const discountValue = ((membership?.cost ?? 0) - costWithDiscount).toFixed(2)

  return {
    discountRate,
    discountValue,
    fullCost,
    finalCost: costWithDiscount.toFixed(2)
  }
}

export const formatMembershipDate = date => {
  return moment(date).format('MMMM DD')
}

export const getPremiumMealsInfo = (membership?: Membership) => {
  const premiumMealsQty = membership?.premiumMeals ?? 1
  const premiumMealsText =
    premiumMealsQty === 1 ? 'premium meal' : 'premium meals'
  return {
    premiumMealsQty,
    premiumMealsText
  }
}
