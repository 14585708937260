import { useEffect, useState } from 'react'
import { CuiSpinner } from 'front-lib'
import { ReactComponent as Bag } from 'src/assets/svg/fullMenu/deliveredtoyourdoor.svg'
import { ReactComponent as Discounts } from 'src/assets/svg/fullMenu/Add-and-Save.svg'
import { ReactComponent as Clock } from 'src/assets/svg/fullMenu/unity-pass-clock.svg'
import { ReactComponent as Sides } from 'src/assets/svg/fullMenu/sides.svg'
import { getModalFooter, getUserStatus } from './../../MembershipBenefits/utils'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import {
  Step,
  MembershipData,
  MembershipOverlayName
} from 'src/types/membership'
import UnityPassImage from 'src/assets/img/unitypass.png'
import './UnityPassBenefits.scss'
import { getPremiumMealsInfo, getUnityPassHeader } from '../utils'

/**
 * Types
 */

interface Props {
  membershipData: MembershipData
  bannerText: string
  loading: boolean
  onContinue: (step: Step) => void
  onClose?: () => void
}

/**
 * UnityPassBenefits component
 */

export function UnityPassBenefits({
  membershipData,
  bannerText,
  onContinue,
  onClose,
  loading
}: Props) {
  const { trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.benefits
  })
  const [status, setStatus] = useState<string>('')
  const { membership, activation } = membershipData
  const { title, secondaryTitle, subtitle } = getUnityPassHeader(membershipData)
  const { premiumMealsQty, premiumMealsText } = getPremiumMealsInfo(membership)

  const Footer = getModalFooter(status)

  useEffect(() => {
    if (!loading) {
      const status = getUserStatus(activation)
      setStatus(status)
      trackModalViewed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (!membership) {
    return null
  }

  function getDeliveryInfo(membership) {
    if (membership?.deliveryDiscountRate === 1) {
      return (
        <div className="benefit-details">
          <span className="benefit-title">No delivery fee</span>
          <span className="benefit-description">
            Enjoy unlimited free deliveries on all your orders.
          </span>
        </div>
      )
    }

    if (membership?.deliveryDiscountRate > 0) {
      return (
        <div className="benefit-details">
          <span className="benefit-title">
            We cover {membership?.deliveryDiscountRate * 100}% of your delivery
            fee
          </span>
          <span className="benefit-description">
            Enjoy discounted delivery on all your orders.
          </span>
        </div>
      )
    }

    return ''
  }

  return (
    <>
      <div className="unity-pass-banner-by-chef">
        <img src={UnityPassImage} alt="Unity Pass" />
        <div className="unity-pass-by-chef">{bannerText}</div>
      </div>
      <div className="unity-pass-benefits-container">
        {loading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <div className="benefits-title-container">
          <span className="title">
            {title} <strong>{secondaryTitle}</strong>
          </span>
          <span className="subtitle">{subtitle}</span>
        </div>
        <div className="benefits-description-container">
          <span className="benefit-container-title">PASSHOLDER BENEFITS</span>
          <div className="benefit-container">
            <div className="image">
              <Bag />
            </div>
            {getDeliveryInfo(membershipData?.membership)}
          </div>
          <div className="benefit-container">
            <div className="image">
              <Discounts />
            </div>
            <div className="benefit-details">
              <span className="benefit-title">Exclusive discounts</span>
              <span className="benefit-description">
                Save up to $3.50 per meal with exclusive Add & Save discounts
                only for Unity Pass holders.
              </span>
            </div>
          </div>
          <div className="benefit-container">
            <div className="image">
              <Sides />
            </div>
            <div className="benefit-details">
              <span className="benefit-title">
                Premium meals at no extra cost
              </span>
              <span className="benefit-description">
                Experience our chefs’ finest creations — savor {premiumMealsQty}{' '}
                {premiumMealsText} per order at no additional cost.
              </span>
            </div>
          </div>
          <div className="benefit-container">
            <div className="image">
              <Clock />
            </div>
            <div className="benefit-details">
              <span className="benefit-title">Annual flex month</span>
              <span className="benefit-description">
                Pause Unity Pass for one month each year and jump back in
                effortlessly — perfect for holidays, travel, or simply a break.
              </span>
            </div>
          </div>
        </div>
        <div className="unity-pass-buttons-container">
          <Footer
            onContinue={onContinue}
            onClose={onClose}
            membership={membership}
          />
        </div>
      </div>
    </>
  )
}
