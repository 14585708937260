import classNames from 'classnames'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import mobileAndTabletcheck from 'src/utils/mobileAndTabletcheck'
import tracking from 'src/shared/tracking'
import useChallengeSteps from './useChallengeSteps'
import { trackExperimentViewed } from 'src/utils/tracking'
import { getChallenge } from 'src/redux-api-bridge/challenge/selectors'
import { CHALLENGE_EXPERIMENTS, CHALLENGE_IDS } from 'src/constants/challenge'
import {
  ChallengeData,
  ChallengeStep,
  ChallengeState
} from 'src/types/challenge'
import { claimReward } from 'src/redux-api-bridge/challenge/effects'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'
import { getDayDiff } from 'src/utils/challenge'

const useChallenge = (challengeId: number) => {
  const dispatch = useDispatch()
  const [challengeData, setChallengeData] = useState({} as ChallengeData)
  const [showChallenge, setShowChallenge] = useState(false)
  const [daysToExpire, setDaysToExpire] = useState(0)
  const [state, setState] = useState(ChallengeState.Pending)
  const { active, data, claim } = useSelector(getChallenge)
  const { user } = useAuth0()
  const { stepsData } = useChallengeSteps(data && data[challengeId])
  const delivery = useSelector(selectedDeliverySelector)

  const classes = classNames({
    'active-challenge__header': showChallenge
  })

  useEffect(() => {
    if (data && data[challengeId]) {
      const activeChallenge = active.find(
        challenge => challenge.challengeId === challengeId
      )
      setChallengeData({
        ...data[challengeId],
        expireAt: activeChallenge?.expireAt
      })
    }

    active.forEach(challenge => {
      if (challenge.enabled) {
        trackChallengeExperimentViewed(challenge.variant)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, challengeId, active])

  useEffect(() => {
    const isCompleted = stepsData?.every(
      (step: ChallengeStep) => step.status === ChallengeState.Completed
    )

    if (isCompleted) {
      setState(ChallengeState.Completed)
    } else {
      setState(ChallengeState.Pending)
    }

    if (challengeData?.finishedAt) {
      setState(ChallengeState.Finished)
    }
  }, [stepsData, challengeData])

  useEffect(() => {
    const isTreatment = active.some(
      challenge =>
        challenge.challengeId === challengeData.id &&
        challenge.enabled &&
        challenge.variant === 'treatment-1'
    )

    switch (challengeData?.id) {
      case CHALLENGE_IDS.FIVE_MILESTONES: {
        setShowChallenge(isTreatment)
        break
      }
      case CHALLENGE_IDS.THREE_ORDERS: {
        const isActive =
          state !== ChallengeState.Pending ||
          (!delivery?.order && daysToExpire > 0)
        setShowChallenge(isTreatment && isActive)
        break
      }
    }
  }, [active, challengeData, daysToExpire, delivery, state])

  useEffect(() => {
    if (challengeData.expireAt) {
      const expireDate = moment(challengeData.expireAt)
      const dayDiff = getDayDiff(expireDate)
      setDaysToExpire(dayDiff)
    }
  }, [challengeData])

  function claimChallengeReward(cta_text: string): void {
    if (claim && !claim.inFlight) {
      dispatch(claimReward(challengeId))
      trackChallengeExperiment('CTA Button Clicked', {
        cta_text
      })
    }
  }

  const trackChallengeExperiment = (eventName = '', customData = {}) => {
    tracking.track(eventName, {
      user_email: user?.email,
      subscriber_id: user?.subscription_id,
      user_id: user?.magento_id,
      ...customData
    })
  }

  const trackChallengeExperimentViewed = (variant: string) => {
    const {
      experiment: { id, name }
    } = CHALLENGE_EXPERIMENTS[challengeId]
    const dynamicProp = mobileAndTabletcheck() ? 'screen_name' : 'page_name'

    trackExperimentViewed({
      variation_name: variant,
      experiment_id: id,
      experiment_name: name,
      user_email: user?.email,
      [dynamicProp]: 'Orders'
    })
  }

  const trackChallengeExperimentState = (eventName = '') => {
    const challengeSteps = challengeData?.steps || []
    const milestones_completed = challengeSteps.reduce(
      (prev: string[], step: ChallengeStep) => {
        return step.status === ChallengeState.Completed
          ? [...prev, step.type]
          : prev
      },
      []
    )

    tracking.track(eventName, {
      user_email: user?.email,
      subscriber_id: user?.subscription_id,
      user_id: user?.magento_id,
      milestones_completed
    })
  }

  return {
    challengeData,
    claim: claim.id === challengeId ? claim : null,
    classes,
    showChallenge,
    stepsData,
    state,
    daysToExpire,
    claimChallengeReward,
    trackChallengeExperiment,
    trackChallengeExperimentState,
    trackChallengeExperimentViewed
  }
}

export default useChallenge
