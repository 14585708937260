import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useSetAtom } from 'jotai'

import tracking from '../../../shared/tracking'
import { getSubscriptionPlan } from '../selectors'
import { useChangeDeliveryPlan } from 'src/api/plan/useChangeDeliveryPlan'
import { useChangeUserPlan } from 'src/api/plan/useChangeUserPlan'
import { useGetAvailablePlans } from 'src/api/plan/useGetAvailablePlans'
import { changePlanModalAtom } from 'src/atoms/modals/changePlanModalAtom.atom'
import { changePlanSuccessModalAtom } from 'src/atoms/modals/changePlanSuccessModalAtom.atom'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'
import { getSubscriptionData } from 'src/redux-api-bridge'
import { toaster } from 'src/utils/toaster'
import { resetUserMenu } from 'src/redux-api-bridge/menu/actions'

export function useChangePlan() {
  const dispatch = useDispatch()
  const location = useLocation()
  const subscriptionPlan = useSelector(getSubscriptionPlan)
  const delivery = useSelector(selectedDeliverySelector)
  const { availablePlans } = useGetAvailablePlans()
  const {
    changeDeliveryPlan,
    loading: loadingDeliveryPlan
  } = useChangeDeliveryPlan({
    onError,
    onSuccess: onChangeDeliveryPlanSuccess
  })
  const { changeUserPlan, loading: loadingUserPlan } = useChangeUserPlan({
    onError,
    onSuccess: onChangeUserPlanSuccess
  })

  const setChangePlanSuccessModal = useSetAtom(changePlanSuccessModalAtom)
  const setChangePlanModal = useSetAtom(changePlanModalAtom)

  function changePlan({
    planId,
    setDefault
  }: {
    planId: number
    setDefault: boolean
  }) {
    if (setDefault) {
      changeUserPlan({ planId })
    } else {
      const shippingDate = delivery?.date

      if (!shippingDate) {
        console.error('Shipping date is required to change plan')
        return
      }

      changeDeliveryPlan({
        planId,
        shippingDate
      })
    }

    trackChangePlan({
      planId,
      data: {
        one_time: !setDefault
      }
    })
  }

  function trackChangePlan({ planId, data = {} }) {
    const selectedPlan = availablePlans.find(plan => plan.id === planId)

    tracking.track('Meal Plan Updated', {
      old_plan_id: subscriptionPlan.id,
      old_plan_name: subscriptionPlan.title,
      new_plan_id: selectedPlan.id,
      new_plan_name: selectedPlan.title,
      ...data
    })
  }

  function onChangeUserPlanSuccess() {
    if (location.pathname.includes('/meals')) {
      window.location.reload()
      return
    }

    dispatch(getSubscriptionData)
    setChangePlanModal({ isOpen: false, type: '' })
    setChangePlanSuccessModal({ isOpen: true, shippingDate: '' })
    dispatch(resetUserMenu())
  }

  function onChangeDeliveryPlanSuccess() {
    const shippingDate = delivery?.displayDate || ''
    setChangePlanModal({ isOpen: false, type: '' })
    setChangePlanSuccessModal({ isOpen: true, shippingDate })
  }

  function onError() {
    toaster.failure(
      <div>
        <span className="bold">Error:</span> An unexpected error has occurred.
        Please try again later.
      </div>,
      { closeButton: false }
    )
  }

  return {
    loading: loadingDeliveryPlan || loadingUserPlan,
    changePlan
  }
}
