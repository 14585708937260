import { useTranslation } from 'react-i18next'
import { CuiIcon, CuiLogo } from 'front-lib'
import { Link } from 'react-router-dom'
import { useRef } from 'react'

import { ChallengeResume } from 'src/components/ChallengeResume/ChallengeResume'
import { CHALLENGE_IDS } from 'src/constants/challenge'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { ChallengeState } from 'src/types/challenge'
import tracking from '../../../../shared/tracking'
import useChallenge from 'src/hooks/useChallenge'
import MenuGreeting from './MenuGreeting'
import MenuSection from './MenuSection'

import './UserMenu.scss'

const UserMenu = ({ isMenuOpen, handleCloseMenu }) => {
  const { t } = useTranslation()
  const userMenuRef = useRef()
  const {
    showChallenge,
    state,
    stepsData,
    claimChallengeReward
  } = useChallenge(CHALLENGE_IDS.FIVE_MILESTONES)
  useOnClickOutside(userMenuRef, handleCloseMenu)

  const showChallengeBanner = showChallenge && state !== ChallengeState.Finished

  if (!isMenuOpen) {
    return null
  }

  const onMenuTabClick = (event, link) => {
    if (link)
      tracking.track('User Menu', {
        category: 'User Menu',
        action: `click on ${t(link.label)}`
      })
    handleCloseMenu(event)
  }

  return (
    <div className="cookunity__user_menu" ref={userMenuRef}>
      <div className="menu_header">
        <CuiIcon
          className="close_button"
          name="close"
          onClick={handleCloseMenu}
        />
        <CuiLogo color="light" />
      </div>
      {!showChallengeBanner && <MenuGreeting />}
      {showChallengeBanner && (
        <ChallengeResume data={stepsData} claimReward={claimChallengeReward} />
      )}
      <MenuSection onMenuTabClick={onMenuTabClick} />
      <div className="menu_section">
        <Link className="menu_item logout" to="/logout">
          {t('header.user_menu.log_out')}
        </Link>
      </div>
    </div>
  )
}

export default UserMenu
