import { CuiButton } from 'front-lib'
import UnityPassImage from 'src/assets/img/unitypass.png'
import './UnityPassConfirmation.scss'
import { MembershipData } from 'src/types/membership'

/**
 * Types
 */

interface Props {
  onClose?: () => void
  membershipData: MembershipData
  bannerText: string
}

/**
 * UnityPassConfirmation component
 */

export function UnityPassConfirmation({
  onClose,
  membershipData,
  bannerText
}: Props) {
  const { membership } = membershipData

  if (!membership) {
    return null
  }

  return (
    <>
      <div className="unity-pass-banner-by-chef">
        <img src={UnityPassImage} alt="Unity Pass" />
        <div className="unity-pass-by-chef">{bannerText}</div>
      </div>
      <div className="confirmation-container">
        <div className="title-container">
          <span className="secondary-title title">All set!</span>
          <span className="title">Delicious perks are coming your way.</span>
          <span className="subtitle">
            Start enjoying Unity Pass benefits on your next order.
          </span>
        </div>
        <div className="unity-pass-buttons-container">
          {onClose && <CuiButton onClick={onClose}>Let's go!</CuiButton>}
        </div>
      </div>
    </>
  )
}
