import { useEffect } from 'react'
import { useAtom } from 'jotai'

import { ResponsiveModalOrBottomSheet } from 'src/components/ResponsiveModalOrBottomSheet/ResponsiveModalOrBottomSheet'

import { membershipModalAtom } from 'src/atoms/modals/membershipModal.atom'
import { useMembershipData } from 'src/api/Membership'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from './useTrackMembershipModal'
import { useMarkModalAsViewed } from 'src/api/Modal/markModalAsViewed'
import { ModalType } from 'src/types/nextModal'
import { MembershipSteps } from './MembershipSteps'

import './MembershipModal.scss'

export function MembershipModal({ upselling = false }) {
  const [{ isOpen, step }, setMembership] = useAtom(membershipModalAtom)
  const { trackModalClosed } = useTrackMembershipModal({ overlayName: step })

  const { membershipData } = useMembershipData()

  function handleClose() {
    trackModalClosed()
    setMembership(prev => ({
      ...prev,
      isOpen: false,
      step: MEMBERSHIP_MODAL_STEPS.closed
    }))
  }

  const { viewModal } = useMarkModalAsViewed()

  useEffect(() => {
    if (upselling) {
      setMembership(prev => ({
        ...prev,
        step: MEMBERSHIP_MODAL_STEPS.introduction,
        isOpen: true
      }))
      viewModal({
        type: ModalType.MembershipModal
      })
    }

    if (
      membershipData?.activation?.active &&
      membershipData?.activation?.skip?.to
    ) {
      setMembership(prev => ({
        ...prev,
        step: MEMBERSHIP_MODAL_STEPS.paused,
        isOpen: true
      }))
      viewModal({
        type: ModalType.MembershipModal
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResponsiveModalOrBottomSheet
      className="membership-modal"
      onClose={handleClose}
      isOpen={isOpen}>
      <MembershipSteps
        membershipData={membershipData}
        handleClose={handleClose}
      />
    </ResponsiveModalOrBottomSheet>
  )
}
