import { useEffect, useState } from 'react'

import { ChallengeState, ChallengeStepWithCopy } from 'src/types/challenge'
import { ProgressBadge } from './components/ProgressBadge'
import { ChallengeIncentive } from './components/ChallengeIncentive'

import './ChallengeResume.scss'

export function ChallengeResume({
  data,
  claimReward
}: {
  data: ChallengeStepWithCopy[]
  claimReward: (cta_text: string) => void
}) {
  const [
    nextPendingStep,
    setNextPendingStep
  ] = useState<ChallengeStepWithCopy | null>(null)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const pendingSteps = data.filter(
      step => step.status === ChallengeState.Pending
    )

    if (pendingSteps.length > 0) {
      const pendingProgress = (100 / data.length) * pendingSteps.length
      const completed = 100 - pendingProgress

      setProgress(completed)
      setNextPendingStep(pendingSteps[0])
    } else {
      setNextPendingStep(null)
      setProgress(100)
    }
  }, [data])

  if (!data || data.length === 0) {
    return null
  }

  return (
    <div className="challenge-resume">
      <ProgressBadge percent={progress} />
      <ChallengeIncentive step={nextPendingStep} claimReward={claimReward} />
    </div>
  )
}
